import styled from "@emotion/styled";
import { ExtraRichTextBlock, RichTextBlock } from "@libry-content/types";
import { PortableText, PortableTextReactComponents } from "@portabletext/react";
import { BlockContentContextProvider } from "./BlockContentContext";
import { LinkSerializer } from "./LinkSerializer";
import { mapStyles, StyleMap } from "./styleMap";
import { TextGallerySerializer } from "./TextGallerySerializer";
import { TextLinkButtonSerializer } from "./TextLinkButtonSerializer";
import { TextTableSerializer } from "./TextTableSerializer";
import VideoEmbedSerializer from "./VideoEmbedSerializer";

export const RichTextStyle = styled.div`
  max-width: 100%;
  > {
    * {
      max-width: 30rem;
      margin-left: auto;
      margin-right: auto;
    }
    p {
      line-height: 1.6;
      margin-bottom: 1.75rem;
    }
    h2 {
      &:not(:first-of-type) {
        margin-top: calc(2vmin + 2rem);
      }
      font-size: 1.5rem;
      font-weight: 600;
    }
    h3 {
      margin-top: 1rem;
      font-size: 1rem;
      font-weight: 600;
    }
    ul,
    ol {
      padding-left: 1.5rem;
      margin-bottom: 2.5rem;
      ul,
      ol {
        margin-bottom: 0;
      }
      li {
        margin-bottom: 0.3rem;
        padding-left: 0.3rem;
        line-height: 1.7;
      }
    }
    ul {
      list-style: disc;
      ul {
        list-style: circle;
      }
    }
    ol {
      list-style: decimal;
    }
  }
`;

const components: Partial<PortableTextReactComponents> = {
  types: {
    table: TextTableSerializer,
    gallery: TextGallerySerializer,
    linkButton: TextLinkButtonSerializer,
    videoEmbed: VideoEmbedSerializer,
  },
  marks: {
    link: LinkSerializer,
  },
};
interface Props {
  blocks?: RichTextBlock | ExtraRichTextBlock;
  styleMap?: StyleMap;
  logInternalLinks?: boolean;
  galleryBackgroundColors?: Record<string, string>;
}

function BlockContent(props: Props) {
  return (
    <BlockContentContextProvider
      logInternalLinks={props.logInternalLinks}
      galleryBackgroundColors={props.galleryBackgroundColors}
    >
      <RichTextStyle>
        <PortableText value={mapStyles(props.styleMap, props.blocks)} components={components} />
      </RichTextStyle>
    </BlockContentContextProvider>
  );
}

export default BlockContent;
